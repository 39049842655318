import { v4 as uuidv4 } from 'uuid' 
import fetch from 'isomorphic-fetch'
// export const APIRoot = 'http://localhost:8001'
export const APIRoot = 'https://admin.bcbchurch.co.uk'
export const graphqlRoot = `${APIRoot}/graphql`

// export const homeHeaderAPI = `${APIRoot}/hompage-header`

// export const aboutHeaderAPI = `${APIRoot}/about-us-header`

// export const eventsHeaderAPI = `${APIRoot}/events-header`
// window.addEventListener( 'load', () => sessionStorage.clear() )

// self.addEventListener('fetch', event => {
//     event.respondWith(
//         caches.match( event.request ).then( response => {
//             // If already cached
//             if ( response ) {
//                 return response;
//             }

//             // If not, fetch request, and then cache response
//             return fetch( event.request ).then( response => {
//                 if ( !response || response.status !== 200 || response.type !== 'basic' ) {
//                     return response;
//                 }
//                 // Stash copy of response
//                 var cachedResponse = response.clone();
//                 caches.open(CACHE_NAME).then( cache => cache.put( event.request, cachedResponse) )
            
//                 return response;
//             } )
//         } )
//     )
// } )

const convertJSONToURL = ( params ) => params ? Object.keys( params ).map( i => `${encodeURIComponent( i )}=${encodeURIComponent( params[i] )}` ).join( '&' ) : ""

/**
 * Functional API's
 */

export const brainTreeAPI = `${APIRoot}/braintree`
export const functionsAPI = `${APIRoot}/functions`

export const getBrainTreeToken = () => fetch( `${brainTreeAPI}` ).then( res => res.json() )
export const getVerseOfTheDay = () => fetch( `${functionsAPI}/votd` ).then( res => res.json() ).then( json => json.rss )



/**
 * Auth API's
 */
export const authAPI = `${APIRoot}/auth`
export const usersAPI = `${APIRoot}/users`
export const loginAPI = `${authAPI}/local`
export const registerAPI = `${loginAPI}/register`

export const loginUser = ( data ) => fetch( loginAPI,
    {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify( data )
    } ).then( res => res.json() )

export const getAllUsers = ( token ) => fetch( usersAPI,
    // {
    //     headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    // } 
).then( res => res.json() )

export const registerUser = ( data ) => fetch( registerAPI,
    {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify( {
            ...data,
            provider: "email",
            username: data.email,
            resetPasswordToken: uuidv4()

        } )
    } ).then( res => res.json() )




/**
 * Media API's
 */
export const getMediaData = ( url ) => fetch( `${APIRoot}/${url}` ).then( res => res.json() )
const queryMediaData = ( url, params ) => fetch( `${APIRoot}/${url}?${convertJSONToURL( params )}` ).then( res => res.json() )

export const getAllVideoData = () => getMediaData( 'video-sermons' )
export const getAllAudioData = () => getMediaData( 'audio-sermons' )

export const queryVideoData = ( params ) => queryMediaData( 'video-sermons', params )
export const queryAudioData = ( params ) => queryMediaData( 'audio-sermons', params )


/**
 * Page data API's
 */
export const missionMapAPI = `${APIRoot}/mission-map-markers`

const getHeader = ( url ) => fetch( `${APIRoot}/${url}` ).then( res => res.json() ).then( ( { images } ) => images && images.map( ( { url } ) => `${APIRoot}${url}` ) )

export const getEvents = () => fetch( `${APIRoot}/events` ).then( res => res.json() )
.then( data => data.map( event => ( {
    ...event,
    image: event.image && event.image.url ? `${APIRoot}${event.image.url}` : undefined,
} ) ) )

export const getFeaturedEvents = () => fetch( `${APIRoot}/featured-events` ).then( res => res.json() )
.then( data => data.events.map( event => ( {
    ...event,
    image: event.image && event.image.url ? `${APIRoot}${event.image.url}` : undefined,
} ) ) )


export const queryEvents = ( params ) => fetch( `${APIRoot}/events?${convertJSONToURL( params )}` ).then( res => res.json() )
.then( data => data.map( event => ( {
    ...event,
    image: event.image && event.image.url ? `${APIRoot}${event.image.url}` : undefined,
} ) ) )

export const queryEventTitle = ( slug ) => fetch( `${APIRoot}/events?event_slug=${slug}` ).then( res => res.json() )
.then( data => data.map( event => ( {
    ...event,
    image: event.image && event.image.url ? `${APIRoot}${event.image.url}` : undefined,
} ) ) )


export const getSundayBanner = () => fetch( `${APIRoot}/welcome-video` ).then( res => res.json() )
.then( data => ( {
    image: data.image && data.image.url ? `${APIRoot}${data.image.url}` : undefined,
} ) ) 

const date_sort = (a, b) => {
    const date1 = new Date(a.date)
    const date2 = new Date(b.date)

    if(date1 < date2) return 1
    else if(date1 > date2) return -1
    return 0
}

export const getTestimonies = () => fetch( `${APIRoot}/testimonies` ).then( res => res.json().then( json => json.sort(date_sort)))


export const queryTestimonies = ( params ) => fetch( `${APIRoot}/testimonies?${convertJSONToURL( params )}` ).then( res => res.json() )


export const queryTestimonyTitle = ( title ) => fetch( `${APIRoot}/testimonies?title_contains=${encodeURIComponent( title )}` ).then( res => res.json() )



export const getNewsletter = () => fetch( `${APIRoot}/newsletter` ).then( res => res.json() )
.then( data => ( { file: data.file && `${APIRoot}${data.file.url}` } ) )


export const sendMessage = ( data ) => fetch( `${APIRoot}/functions/contact`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify( data )
} ).then( res => res.json() )

export const subscribeToMailchimp = ( data ) => fetch( `${APIRoot}/functions/subscribe`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify( data )
} ).then( res => res.json() )


export const sendPrayerRequest = ( data ) => fetch( `${APIRoot}/functions/prayer-request`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify( data )
} ).then( res => res.json() )

export const sendHallForHire = ( data ) => fetch( `${APIRoot}/functions/hall-for-hire`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify( data )
} ).then( res => res.json() )

export const sendLifeGroup = ( data ) => fetch( `${APIRoot}/functions/lifegroups`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify( data )
} ).then( res => res.json() )

export const sendGiftAidDetails = ( data ) => fetch( `${APIRoot}/gift-aids`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify( data )
} ).then( res => res.json() )

export const sendGiftAidConfirmation = ( data ) => fetch( `${APIRoot}/functions/gift-aid/confirmation`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify( data )
} ).then( res => res.json() )


export const getVimeoThumbnail = id => fetch( `${APIRoot}/functions/vimeo-thumbnail/${id}`, {
} ).then( res => res.json() ).then( data => data[0] || data )


export const getHomeHeader = () => getHeader( 'homepage-header' )
export const getAboutUsHeader = () => getHeader( 'about-us-header' )
export const getMediaHeader = () => getHeader( 'media-header' )
export const getEventsHeader = () => getHeader( 'events-header' )

export const getMissionMapMarkers = () => fetch( missionMapAPI ).then( res => res.json() )


export const getStaticPageData = async ( staticPage ) => {
    const url =  `${APIRoot}/${staticPage}` 
    const pageData = await fetch( url ).then( res => res.json() )
    if( !pageData )
        return null
    
    const banners = pageData.banners && pageData.banners.map( ( { title, text, image, __component: component, section_id: sectionId, cards, button } ) => ( 
        { 
            component, 
            title, 
            text, 
            sectionId, 
            image: image && image.url ? `${APIRoot}${image.url}` : undefined,
            cards: cards && cards.map( card => ( { ...card, image: `${APIRoot}${card.image.url}` } ) ),
            buttons:  button && button.map( b => ({...b, buttonStyle: 'transparent-button', text: b.label })),
        } )
    )

    const information_banners = pageData.information_banners && pageData.information_banners.map( ( { title, text, link, full_width, image, __component: component, section_id: sectionId, cards, button } ) => ( 
        { 
            component, 
            title, 
            text, 
            sectionId, 
            image: image && image.url ? `${APIRoot}${image.url}` : undefined,
            link,
            full_width,
            cards: cards && cards.map( card => ( { ...card, image: `${APIRoot}${card.image.url}` } ) ),
            buttons:  button && button.map( b => ({...b, buttonStyle: 'transparent-button', text: b.label })),
        } )
    )

    const header = !pageData.header ? undefined : 
    {
        images: pageData.header.images ? pageData.header.images.map( ( { url } ) => `${APIRoot}${url}` ) : undefined,
        title: pageData.header.title,
        text: pageData.header.text,
    }
    
    const page = { header, banners, information_banners }
    localStorage.setItem( url, page )
    return page
}


export const getPageData = async ( url=null ) => {
    const [ pageData ] = await fetch( `${APIRoot}/pages?url=${url}` ).then( res => res.json() )
    if( !pageData )
        return null
    
    const banners = pageData.banners && pageData.banners.map( ( { title, text, image, __component: component, section_id: sectionId, cards, button } ) => ( 
        { 
            component, 
            title,
            text,
            sectionId, 
            image: image && image.url ? `${APIRoot}${image.url}` : undefined,
            cards: cards && cards.map( card => ({...card, image: `${APIRoot}/${card.image.url}` })),
            buttons: button && button.map( b => ({...b, buttonStyle: 'transparent-button', text: b.label })),
        } )
    )

    const header = !pageData.header ? undefined : 
    {
        images: pageData.header.images ? pageData.header.images.map( ( { url } ) => `${APIRoot}${url}` ) : undefined,
        title: pageData.header.title,
        text: pageData.header.text,
    }
    
    const page = { header, banners }
    return page
}


export const getAllPages = async () => {
    let pages = await fetch( `${APIRoot}/pages` ).then( res => res.json() )
    

    pages = pages.map( pageData => {
        const banners = pageData.banners && pageData.banners.map( ( { title, text, image, __component: component, section_id: sectionId, cards, button } ) => ( 
            { 
                component, 
                title,
                text,
                sectionId, 
                image: image && image.url ? `${APIRoot}${image.url}` : undefined,
                cards: cards && cards.map( card => ({...card, image: `${APIRoot}/${card.image.url}` })),
                buttons: button && button.map( b => ({...b, buttonStyle: 'transparent-button', text: b.label })),
            } )
        )
    
        const header = !pageData.header ? undefined : 
        {
            images: pageData.header.images ? pageData.header.images.map( ( { url } ) => `${APIRoot}${url}` ) : undefined,
            title: pageData.header.title,
            text: pageData.header.text,
        }
        
        const page = { header, banners, url: pageData.url, name: pageData.name }
        return page

    })

    return pages
    
}