import React, { useState, useEffect } from 'react'

import Link from './Link'
// import Logo from '../assets/images/logo/new-logo.png'
import Logo from '../assets/images/logo/new-logo-2024.png'

import { stack as Menu } from 'react-burger-menu'
import { useScreen } from '../lib/hooks'

const Navbar = () => {
    const [ windowWidth, setWindowWidth ] = useState( typeof window !== 'undefined' ? window.screen.width : 1920 )
    const [ navHidden, setNavHidden ] = useState( true )
    const handleResize = () => setWindowWidth( typeof window !== 'undefined'  ? window.innerWidth : 1920 )
    const [ navStyle, setNavStyle ] = useState( { zIndex: 1000 } )
    const [ scrollPos, setScrollPos ] = useState( typeof window !== 'undefined' ? window.pageYOffset : 0 )
    const [ burgerMenuOpen, setBurgerMenuOpen ] = useState( false )
    const isMobile = useScreen()

    const hideNav = () => {
        setNavHidden( true )
        setBurgerMenuOpen( false )
        let element = document.getElementsByClassName( "bm-overlay" )[0]
        element.click()
        console.log( burgerMenuOpen )
    }


    useEffect( () => {
        if( typeof window !== 'undefined' ){
            window.addEventListener( 'resize', handleResize  )
            handleResize()
            window.addEventListener( 'scroll', () => setScrollPos( window.pageYOffset )  )

    
            return window.removeEventListener( 'resize', handleResize )
        }
       
    }, [] )


    useEffect( () => {
        // console.log(  scrollPos  )
        if ( scrollPos > 100 ) {
            setNavStyle( { zIndex: 1000 } )
        } else {
            setNavStyle( { backgroundColor: 'rgba(255,255,255,1)', zIndex: 1000 } )
        }

    }, [ scrollPos ] )

    const links = [
        { text: 'Home', url: '/' },
        { text: 'About Us', url: '/about-us' },
        { text: 'Services & Media', url: '/media' },
        { text: "What's On", url:'/whats-on' },
        { text: 'Church Life', url:'/church-life' },
        { text: 'Serving', url:'/serving' },
        { text: 'Mission', url:'/missions' },
        { text: 'Contact Us', url:'/contact' },
        { text: 'Donations', url:'/giving' },
        { text: 'Hall Hire', url:'/hall-for-hire' },
        { text: 'Jobs', url:'/jobs' },
    ]

    const urlMatch = ( url ) => {
        // const urlSplit =  url.slice( url.length - 1 ) === "/" ?  url.slice( 0, -1 ).split( '/' ) : url.split( "/" )
        const urlSplit = url.split( "/" )
        let locationUrlSplit = [] 
        if( typeof window!=='undefined' ) {
            const currentPath = window.location.href
            locationUrlSplit = currentPath.slice( currentPath.length - 1 ) === "/" ?  currentPath.slice( 0, -1 ).split( '/' ) : currentPath.split( "/" )
        }

        if ( urlSplit[1] === "" )
            return locationUrlSplit.length === 3
        
        return locationUrlSplit.includes( urlSplit[1] ) && locationUrlSplit[ locationUrlSplit.length - 1 ] !== ""
    }

    const setActiveUrl = ( url ) => urlMatch( url ) ? " active" : ""
    const logoSize = 80
    const logoStyle = { 
        position: 'absolute', 
        left: '5%', 
        top: 5, 
        float: 'left',
        transform: 'scale(0.85)'
    }
    const logoStyleMobile = { 
        ...logoStyle,
        left: 25,
        top: 2,
        transform: 'scale(0.5)',

    }

    return isMobile ? (
        <div className="navbar" style={navStyle}>
                {/* <div className="navbar-menu-button" onClick={() => setNavHidden( !navHidden )}><Collapse active={!navHidden}/></div> */}
                <img src={Logo} width={logoSize} height={logoSize} style={logoStyleMobile} />

                <Menu width='100%' isOpen={burgerMenuOpen}>
                    { links.map( ( { text, url } ) => <div className={`navbar-link menu-item${setActiveUrl( url )}`} key={url}><Link onClick={() => hideNav()} to={url}>{text}</Link></div> )}
                    {/* <div className='navbar-link'><AniLink fade duration={0.4} to="/login" className="fa fa-user-circle-o" style={{ border: "none" }}></Link></div> */}
                </Menu>
        </div>
    ) :

    (
        <div className="navbar" style={navStyle}>
            <img src={Logo} width={logoSize} height={logoSize} style={logoStyle} />
            { links.map( ( { text, url } ) => <div className={`navbar-link${setActiveUrl( url )}`} key={url}><Link to={url}>{text}</Link></div> )}
            {/* <div className='navbar-link'><AniLink paintDrip hex="#ffffff" duration={0.6} to="/login" className="fa fa-user-circle-o" style={{ border: "none" }}></AniLink></div> */}
        </div>
    )

}


export default Navbar
