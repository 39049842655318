// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-church-life-js": () => import("./../../../src/pages/church-life.js" /* webpackChunkName: "component---src-pages-church-life-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-giving-js": () => import("./../../../src/pages/giving.js" /* webpackChunkName: "component---src-pages-giving-js" */),
  "component---src-pages-hall-for-hire-js": () => import("./../../../src/pages/hall-for-hire.js" /* webpackChunkName: "component---src-pages-hall-for-hire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-lifegroups-js": () => import("./../../../src/pages/lifegroups.js" /* webpackChunkName: "component---src-pages-lifegroups-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-media-audio-js": () => import("./../../../src/pages/media/audio.js" /* webpackChunkName: "component---src-pages-media-audio-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-media-prayer-meetings-js": () => import("./../../../src/pages/media/prayer-meetings.js" /* webpackChunkName: "component---src-pages-media-prayer-meetings-js" */),
  "component---src-pages-media-videos-js": () => import("./../../../src/pages/media/videos.js" /* webpackChunkName: "component---src-pages-media-videos-js" */),
  "component---src-pages-missions-js": () => import("./../../../src/pages/missions.js" /* webpackChunkName: "component---src-pages-missions-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-serving-js": () => import("./../../../src/pages/serving.js" /* webpackChunkName: "component---src-pages-serving-js" */),
  "component---src-pages-testimonies-js": () => import("./../../../src/pages/testimonies.js" /* webpackChunkName: "component---src-pages-testimonies-js" */),
  "component---src-pages-whats-on-js": () => import("./../../../src/pages/whats-on.js" /* webpackChunkName: "component---src-pages-whats-on-js" */)
}

